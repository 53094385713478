/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }

body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.wrning-tag {
    width: 100%;
    margin-top: 0.25rem;
    font-size: .875em;
    color: #ef4d56;
  }
  .select-placeholder{
    color:#7377c2
  }

html, body { height: 100%; }

body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.sticky-div {
  z-index: 998;
  position: sticky;
  top: 60px;
  background: #f5f5f9;
  border-radius: 3px;
  margin-bottom: 9px;
  padding-left: 10px;
  margin-top: -25px;
}
.button-items {
  .line {
    font-size: 30px;
    padding: 0;
  }

  button {
    border: none;
  }

  i {
    font-size: 2rem !important;
    font-weight: lighter;
  }

  .btn:disabled {
    border-color: grey;
    color: grey;
  }

}
.control-button {
  .btn {
    border: none;

    i {
      font-size: 1.5rem;
    }

    .main {
      color: whitesmoke;
    }
  }
}

.text-primary{
  font-size: 15px;
}
.las{
  cursor: pointer;
}
.icon-style{
  cursor: pointer;
}

.btn-primary {
  //width: 200px !important;
}
